import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { styled } from '@mui/material/styles';

interface Props {}

const StyledTextField = styled(MuiTextField)`
  & .MuiOutlinedInput-root {
    background-color: var(--iq-robot-surface, #2d313d);
    & fieldset {
      border: 1px solid transparent;
    }
    &:hover fieldset {
      border: 2px solid var(--iq-robot-primary, #40cd1c);
    }
  }

  & .MuiFormHelperText-root {
    font-size: var(--text-subhead-size, 14px);
    margin-left: 0px;
  }

  & input:autofill {
    -webkit-box-shadow: unset !important;
  }

  & input {
    box-sizing: border-box;
    height: inherit;
  }

  caret-color: var(--iq-robot-primary, #40cd1c);
`;

const TextField: React.FC<Props & TextFieldProps> = React.forwardRef((props, ref) => {
  const { className } = props;
  return <StyledTextField ref={ref} className={className} {...props} />;
});

TextField.displayName = 'TextField';

export default React.memo(TextField);
