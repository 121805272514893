import React from 'react';

import SuccessIcon from '@/icons/success';
import { useTranslation } from '@/hooks/useTranslation';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { useMobile } from '@/hooks/useMobile';
import Button from '@mui/material/Button';
import { publicRuntimeConfig } from '@repo/config';

interface Props {
  email: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, sans-serif;
`;
const IconContainer = styled.div<{ isMobile: boolean }>`
  width: 64px;
  height: 64px;
  margin-bottom: ${(props) => (props.isMobile ? '16px' : '24px')};
`;
const TextBlock = styled.div`
  margin-bottom: 28px;
`;
const TextContainer = styled.div<{ isMobile: boolean }>`
  margin-bottom: 36px;
  width: 100%;
  display: inline-block;
  font-size: ${(props) => (props.isMobile ? 'var(--text-subhead-size, 14px)' : 'var(--text-body-size, 16px)')};

  &:last-child {
    margin-bottom: 0;
  }
`;
const Email = styled.span`
  font-weight: 700;
`;

const SuccessMessage: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  return (
    <Root>
      <IconContainer isMobile={isMobile}>
        <SuccessIcon />
      </IconContainer>
      <TextBlock>
        <TextContainer isMobile={isMobile}>
          <Trans
            ns="common"
            i18nKey="text.email_with_instructions"
            values={{ email }}
            components={{
              Email: <Email />,
            }}
            tOptions={{ email }}
          />
        </TextContainer>
        <TextContainer isMobile={isMobile}>{t('text.take_5_minutes')}</TextContainer>
        <TextContainer isMobile={isMobile}>
          {t('text.return_after_recovering', { robotName: t(publicRuntimeConfig.app.nameKey) })}
        </TextContainer>
      </TextBlock>

      <Button
        type="submit"
        fullWidth
        sx={{
          textTransform: 'initial',
          backgroundColor: 'var(--iq-robot-alt-surface, #465066)',
        }}
      >
        {t('button.back_to_login')}
      </Button>
    </Root>
  );
};

export default SuccessMessage;
