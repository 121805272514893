import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { useConfigContext } from '@/context/ConfigContext';
import styled from '@emotion/styled';
import { useTranslation } from '@/hooks/useTranslation';
import { AnalyticClient, DocumentTypes, getPathnameFromUrl } from '@repo/utils';

const defaultLinkProps = { passHref: true, target: '_blank' };

const Link = styled.a`
  color: var(--iq-robot-primary, #40cd1c);
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
`;

const TermsText: React.FC = () => {
  const { t } = useTranslation();
  const {
    configState: { value },
  } = useConfigContext();

  const handleOpenLinkClick = useCallback((type: DocumentTypes) => {
    AnalyticClient.track({ type: 'Terms - Open', data: { type } });
  }, []);

  const robotTermsConditionsUrl = useMemo(() => {
    return getPathnameFromUrl(value?.robot_terms_conditions_url, '/terms');
  }, [value?.platform_terms_conditions_url]);
  const robotPrivacyPolicyUrl = useMemo(() => {
    return getPathnameFromUrl(value?.robot_privacy_policy_url, '/terms/privacy-policy');
  }, [value?.platform_privacy_policy_url]);

  return (
    <Typography
      sx={{
        color: 'var(--common-text-grey, #98A4B3)',
        fontSize: '12px',
        lineHeight: 1.33,
        marginTop: '16px',
      }}
    >
      <Trans
        ns="common"
        i18nKey="link.terms_privacy"
        components={{
          LandingTerms: (
            <Link
              {...defaultLinkProps}
              href={robotTermsConditionsUrl}
              onClick={() => handleOpenLinkClick('Our Terms')}
            />
          ),
          LandingPrivacy: (
            <Link
              {...defaultLinkProps}
              href={robotPrivacyPolicyUrl}
              onClick={() => handleOpenLinkClick('Our Policy')}
            />
          ),
          PlatformTerms: (
            <Link
              {...defaultLinkProps}
              href={value?.platform_terms_conditions_url}
              onClick={() => handleOpenLinkClick('Platform Terms')}
            />
          ),
          PlatformPrivacy: (
            <Link
              {...defaultLinkProps}
              href={value?.platform_privacy_policy_url}
              onClick={() => handleOpenLinkClick('Platform Privacy')}
            />
          ),
        }}
        tOptions={{ appName: t('app_name') }}
      />
    </Typography>
  );
};

export default React.memo(TermsText);
