import { useConfigContext } from '@/context/ConfigContext';
import { State as RequestState } from '@/hooks/useRequest';

import { useEffect } from 'react';
import { useNisy } from './useNisy';

import { IqUserDto } from '@/Iq/types';
import { AuthTabsValues } from '@/context/AuthContext';
import {
  AnalyticClient,
  OneSignalClient,
  AmplitudeClient,
  NisyEvents,
  RequestStatuses,
  redirectToApp,
} from '@repo/utils';

export const useAnalyticsAuthForm = (requestState: RequestState<{ user: IqUserDto }>, type: AuthTabsValues) => {
  const {
    configState: { value },
  } = useConfigContext();
  const { onIdentify, onTrack } = useNisy();

  const trackLoginSuccess = async () => {
    const user = requestState.result.payload.user;
    AnalyticClient.track(
      {
        type: 'Login - Success',
        data: {
          account: user.ourUser ? 'Our' : 'Wrong',
          currency: user.currency,
          method: 'Email Auth',
        },
      },
      {
        nisyOptions: {
          withAttribution: true,
        },
      },
    );
    if (!user.ourUser) {
      AnalyticClient.track({ type: 'Login Wrong Account' });
      AnalyticClient.track({ type: 'Registration or Login Wrong Account' });
    }

    await onTrack(
      NisyEvents.LOGIN,
      {
        email: user.email,
        platformId: user.traderId,
      },
      {
        withAttribution: true,
      },
    );

    await AnalyticClient.userStore.storeFirstLogin();
  };

  const trackRegisterSuccess = async () => {
    const user = requestState.result.payload.user;
    const email = user.email;
    AnalyticClient.userStore.storeFirstReg();
    await OneSignalClient.setEmail(email);

    AnalyticClient.setUserProps({
      'Platform ID': String(user.traderId),
      Currency: user.currency,
      Registered: true,
      Language: AnalyticClient.getLanguageFullName(),
      Platform: 'web_app',
      'Start Version': AnalyticClient.getStartWebAppVersion(),
    });
    AnalyticClient.track(
      {
        type: 'Registration - Success',
        data: {
          from: type === AuthTabsValues.Register ? 'Registration' : 'Account activation',
          affiliate_id: value?.a_id,
          country: user.registrationCountry,
          deviceID: AnalyticClient.getUid(),
          currency: user.currency,
          method: 'Email Auth',
        },
      },
      {
        nisyOptions: {
          withAttribution: true,
        },
      },
    );

    await onTrack(
      NisyEvents.REGISTER,
      {
        email,
        platformId: user.traderId,
      },
      {
        withAttribution: true,
      },
    );

    if (type === AuthTabsValues.Register) {
      AnalyticClient.track({
        type: 'Registration or Login Wrong Account',
      });
    }
  };

  const trackSuccess = async () => {
    const user = requestState.result.payload.user;
    AnalyticClient.userStore.setUser(user);
    AmplitudeClient.updateUserID();
    await onIdentify(user);

    if (type === AuthTabsValues.Login) {
      await trackLoginSuccess();
      redirectToApp();
      return;
    }

    if (type === AuthTabsValues.Register) {
      await trackRegisterSuccess();
      redirectToApp();
      return;
    }
  };

  const trackFailed = () => {
    const hasErrors = requestState.errors && requestState.errors.length;
    if (!hasErrors) {
      return;
    }

    const error = requestState.errors?.[0]!;
    const errorMessage = error?.message;
    const data = {
      message: errorMessage,
      code: error.statusCode,
    };
    if (type === AuthTabsValues.Login) {
      AnalyticClient.track({
        type: 'Login - Error',
        data,
      });
    }
    if (type === AuthTabsValues.Register) {
      AnalyticClient.track({
        type: 'Registration - Error',
        data,
      });
    }
  };

  useEffect(() => {
    if (requestState.status === RequestStatuses.Succeeded) {
      trackSuccess();
      return;
    }

    trackFailed();
  }, [requestState.errors, requestState.status]);

  useEffect(() => {
    if (type === AuthTabsValues.Register) {
      AnalyticClient.track({ type: 'Registration - Screen', data: { from: 'Loading' } });
      return;
    }

    if (type === AuthTabsValues.Login) {
      AnalyticClient.track({
        type: 'Login - Screen',
      });
      return;
    }
  }, [type]);
};
